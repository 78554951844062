import React from "react";
import { connect, useSelector } from "react-redux";
import { ExchangeTradedFuturesQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { ContentBasic } from "../../components/common";
import BANNER from "../../images/siteImages/exchangeTradeFutures/EXCHANGE_TRADED_FUTURES_BANNER.jpg";

const ExchangeTradedFutures = () => {
  const language = useSelector((state) => state.language);
  const exchangeTradedFuturesData = ExchangeTradedFuturesQuery(language);
  return (
    <Layout>
      <ContentBasic {...exchangeTradedFuturesData} banner_image={BANNER} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(ExchangeTradedFutures);
